<template>
  <div>
    <div class="constent">
      <div class="header_nav">
        <h1>关于我们</h1>
        <p style="color: #333">为您提供一站式供应链解决方案</p>
      </div>
    </div>

    <div class="marginAuto contWidth1440">
      <div style="margin: 122px 0" class="display align-items">
        <div style="width: 715px">
          <h1>效率成就品牌，诚信铸就未来</h1>
          <p>
            万鲸科技有限公司，致力于打造极具影响力的企业数字化服务平台，为企业赋能。
          </p>
          <p style="margin-top: 30px; line-height: 35px">
            公司专注于企业数字营销领域，覆盖APP、公众号、小程序、H5等渠道，高效匹配供应链，连接品牌、商家和用户三
            方诉求，致力于打造全链路数字化生态体系。公司从成立以来，已为全国众多企业提供专业的解决方案，助力企业快
            速转型，赋能企业搭建流量变现体系，精细化用户运营转型，激活存量，裂变增量。并为企业提供多种全渠道全场景
            的SAAS化应用，以及安全稳定、方便高效的技术支持，降低企业运营成本，提升企业利润。
          </p>
        </div>
        <div>
          <img src="/mp/VirHome/she.png" alt="" />
        </div>
      </div>
    </div>

    <div class="marginAuto contWidth1440 display align-items">
      <div
        class="align-items"
        style="border: 1px solid #d8d8d8; padding: 25px; width: 464px"
      >
        <img style="width: 80px" src="/mp/about/2.png" alt="" />
        <div style="margin-left: 25px">
          <h4>企业价值观</h4>
          <p style="color: #666">以客户为中心客户至上，高效务实</p>
        </div>
      </div>
      <div
        class="align-items"
        style="border: 1px solid #d8d8d8; padding: 25px; width: 464px"
      >
        <img style="width: 80px" src="/mp/about/4.png" alt="" />
        <div style="margin-left: 25px">
          <h4>企业愿景</h4>
          <p style="color: #666">诚信服务，合作共赢，致力于打</p>
          <p style="color: #666">造极具影响力的企业数字化服务平台</p>
        </div>
      </div>
      <div
        class="align-items"
        style="border: 1px solid #d8d8d8; padding: 25px; width: 464px"
      >
        <img style="width: 80px" src="/mp/about/3.png" alt="" />
        <div style="margin-left: 25px">
          <h4>企业使命</h4>
          <p style="color: #666">专业专注，创新极致</p>
          <p style="color: #666">专注于科技创新，助力企业数字化转型</p>
        </div>
      </div>
    </div>

    <div>
      <div class="container mt-120 flex-row">
        <div style="flex: 6">
          <h2>不如写一写，能让我们更懂您</h2>
          <p class="text-lg">我们为您提供一体化互联网电商整套营销方案</p>
          <a-form-model class="mt-60 contact-form">
            <a-row :gutter="[24, 56]">
              <a-col :span="12">
                <a-input v-model="form.name" placeholder="请输入您的姓名">
                </a-input>
              </a-col>
              <a-col :span="12">
                <a-input
                  v-model="form.unitName"
                  placeholder="请输入您的公司名称："
                >
                </a-input>
              </a-col>
              <a-col :span="12">
                <a-input
                  v-model="form.phone"
                  type="number"
                  placeholder="请输入您的电话："
                >
                </a-input>
              </a-col>
              <a-col :span="12">
                <a-input
                  v-model="form.email"
                  type="email"
                  placeholder="请输入您的邮箱："
                >
                </a-input>
              </a-col>
              <a-col :span="24">
                <a-textarea
                  v-model="form.remark"
                  placeholder="请输入您要咨询的内容："
                  type="textarea"
                  style="height: 168px"
                >
                </a-textarea>
              </a-col>
            </a-row>
          </a-form-model>
        </div>
        <div
          style="
            padding: 40px 30px;
            background-color: #f6f8fa;
            margin-left: 80px;
            flex: 4;
          "
        >
          <p class="text-xl" style="color: #333">和我们聊一聊</p>
          <hr class="mt-30" color="#D8D8D8" />
          <div class="mt-30 flex-row flex-col-center">
            <img src="/mp/icon/phone.png" alt="" />
            <span class="text-lg">客服电话（7*24/小时）</span>
            <span class="text-primary text-lg font-bold">0755-83899969</span>
          </div>

          <div class="mt-20 flex-row flex-col-center">
            <img src="/mp/VirHome/two.png" alt="" />
            <span class="text-lg">联系邮箱：</span>
            <span class="text-primary text-lg font-bold"
              >jingdan@wonwhale.com</span
            >
          </div>

          <div class="mt-20 flex-row flex-col-center">
            <img src="/mp/VirHome/tree.png" alt="" />
            <span class="text-lg">公司地址：</span>
            <span class="text-lg"
              >广东省深圳市南山区北京航空航天大厦1座7003</span
            >
          </div>

          <div
            style="
              margin-top: 60px;
              margin-bottom: 30px;
              font-size: 24px;
              color: #333;
            "
          >
            关注我们
          </div>
          <hr class="mt-35 mb-35" color="#D8D8D8" />

          <img
            src="//file.jingpingo.com/1/material/4bdbd8a2-976e-4840-8819-a45208c7d326.png"
            alt=""
          />
          <p style="margin-top: 10px; margin-left: 40px">官方微信</p>
        </div>
      </div>
      <div class="container" style="margin-top: 60px">
        <a-button type="primary" class="submit-btn" @click="save"
          >立即提交</a-button
        >
      </div>
    </div>

    <div class="container mt-120">
      <div class="bigmap">
        <div id="search" class="mappp computer" />
        <!-- <img class="pcmap" src="../../assets/img/about/pcmap.png" alt=""> -->
        <!-- <img class="pcmap" src="../../assets/img/about/mapL.png" alt=""> -->
      </div>
    </div>

    <div class="flex-row mt-120">
      <img
        src="//file.jingpingo.com/1/material/f3789a5a-0205-4965-b006-6b19dfce2734.png"
        alt=""
      />
      <div class="contact-banner" style="width: 738px">
        <p class="text-xl text-gray-1">亦可留言</p>
        <p class="text-xl mt-15 text-gray-1">
          免费获得《数字化服务平台建设方案》及报价
        </p>
        <a-button type="info" class="contact-banner-btn" @click="open">
          注册体验
          <img src="/mp/icon/arropw-primary.png" alt="" class="ml-10" />
        </a-button>
      </div>
    </div>
  </div>
</template>

<script>
import { getAdd } from "@/api/HelpCenter";
export default {
  name: "ContactUs",
  data() {
    return {
      form: {},
    };
  },
  mounted() {
    setTimeout(function () {
      let zoom = 16;
      const initw = document.body.clientWidth;
      if (initw > 500) {
        zoom = 16;
      } else {
        zoom = 14;
      }
      // 地图1
      // eslint-disable-next-line no-undef
      var map = new AMap.Map("search", {
        resizeEnable: true, // 是否监控地图容器尺寸变化
        zoom: zoom, // 初始化地图层级
        center: [113.941005, 22.527411], // 初始化地图中心点
      });

      // eslint-disable-next-line no-undef
      var endMarker = new AMap.Marker({
        position: map.center,
        icon: require("@/assets/dir-marker.png"),
      });
      map.add([endMarker]);

      // 监听宽度
      // const that = this
      window.onresize = () => {
        return (() => {
          window.screenWidth = document.body.clientWidth;
          if (window.screenWidth < 500) {
            map.setZoom(14);
          } else {
            map.setZoom(16);
          }
        })();
      };
    }, 0);
  },
  methods: {
    open() {
      window.open(process.env.VUE_APP_SET_URL, "_blank");
    },
    save() {
      // /system/mpgather/add
      if (!this.form.name) return this.$message.error("请输入您的姓名");
      if (!this.form.unitName) return this.$message.error("请输入您的公司名称");
      if (!this.form.phone) return this.$message.error("请输入您的电话");
      if (!this.form.email) return this.$message.error("请输入您的邮箱");
      if (!this.form.remark) return this.$message.error("请输入您要咨询的内容");
      console.log("请输入您的姓名", this.form.name);
      getAdd(this.form).then((res) => {
        if (res.code == 200) {
          this.$message.success("提交成功");
          this.form.name = "";
          this.form.unitName = "";
          this.form.phone = "";
          this.form.email = "";
          this.form.remark = "";
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.contact-banner {
  padding-left: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  background-image: url("/images/bg2.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 10%;
  background-attachment: fixed;
  .contact-banner-btn {
    background-color: #f6f8fa;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-primary;
    width: 173px;
    height: 53px;
    margin-top: 30px;
  }
}
::v-deep .amap-icon img {
  width: 50px;
  height: 50px;
}
.mappp {
  width: 100%;
  height: 670px;
}
.submit-btn {
  width: 205px;
  height: 65px;
  font-size: 18px;
}
.nav-btn {
  width: 155px;
  margin-top: 55px;
  height: 45px;
  font-size: 18px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #666666;
  &:hover {
    border-color: white;
  }
}
.contact-form {
  .ant-input {
    background-color: #f6f8fa;
    min-height: 58px;
    border: none;
  }
  input::placeholder {
    color: #999999;
  }
  textarea::placeholder {
    color: #999999;
    line-height: 30px;
  }
}
.constent {
  .header_nav {
    background-image: url(../.../../../assets/home/bsc.png);
    width: 100%;
    height: 450px;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 40px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei, serif;
    color: #fff;
    padding-left: 230px;
    div {
      &:first-child {
        font-size: 46px;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei, serif;
        font-weight: 700;
        color: #333;
      }
      &:last-child {
        font-size: 23px;
        font-weight: 400;
        color: #666;
        margin-top: 22px;
      }
    }
  }
}
</style>
